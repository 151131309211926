export const validateEmail = (value) => {
  if (!/\S+@\S+\.\S+/.test(value)) {
    return 'Введите корректный email';
  }
  return undefined;
};

export const validateRequiredField = (value) => {
  if (!value) {
    return 'Поле обязательно для заполнения';
  }
  return undefined;
};

export const validateForm = (values) => {
  const errors = {};
  errors.email = validateEmail(values.email);
  errors.name = validateRequiredField(values.name);
  errors.problem = validateRequiredField(values.problem);
  errors.description = validateRequiredField(values.description);
  return errors;
};
