import { Dialog, DialogActions } from '@mui/material';
import { useDialog } from '../../../providers/dialog.provider';
import { Field, Form } from 'react-final-form';
import Input from '../../../components/input';
import Button from '../../../components/button';
import { useUpdateStandardSizeMutation } from '../../../services';
import { createNotification } from '../../../utils/notifications';
import { getChangedFormValues, isValidUrl } from '../../../utils/common';

export const SizeEditModal = ({ value }) => {
  const dialog = useDialog();
  const [updateSize] = useUpdateStandardSizeMutation();
  const { name, url, length, height, width, maximum_load_weight, size_units, weight_units } = value;

  const handleEdit = async (formValues) => {
    const changedFields = getChangedFormValues(value, formValues)
    
    if (Object.keys(changedFields).length === 0) {
      dialog.close()
      return;
    }
    
    const { id } = value;
    
    await updateSize({
      id,
      body: changedFields,
    })
      .unwrap()
      .then(() => {
        createNotification('Изменения сохранены');
        dialog.close();
      });
  };

  const validate = (values) => {
    let errors = {};
    
    if (values?.url && !isValidUrl(values.url)) {
      errors.url = 'Введите корректый URL';
    }
    const numericFields = ['length', 'height', 'width', 'maximum_load_weight'];
    
    numericFields.forEach((field) => {
      if (isNaN(Number(values[field]))) {
        errors[field] = 'Введите число';
      }
    });

    return errors
  };

  return (
    <Dialog
      open={dialog.visibility}
      sx={{
        '& .MuiPaper-root': {
          minWidth: '584px',
          margin: '32px',
          height: 'fit-content',
          maxHeight: 'calc(100% - 64px)',
        },
      }}
    >
      <div className="tw-bg-white tw-p-6 tw-flex tw-flex-col">
        <h3 className="tw-text-[#191919] tw-font-semibold tw-mb-6">Редактирование товара</h3>
        <Form
          onSubmit={handleEdit}
          validate={validate}
          initialValues={{
            name,
            url,
            length,
            height,
            width,
            maximum_load_weight,
            size_units,
            weight_units,
          }}
        >
          {({ handleSubmit, submitting, invalid, pristine, modified }) => (
            <div className="tw-flex tw-flex-col">
              <div className="tw-flex tw-flex-col tw-gap-[10px] tw-mb-6">
                <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">Наименование в проекте</p>
                <Field name="name">{({ input }) => <Input {...input} required />}</Field>
              </div>
              <div className="tw-flex tw-flex-col tw-gap-[10px] tw-mb-[45px]">
                <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">URL</p>
                <Field name="url">{({ input }) => <Input {...input} required />}</Field>
              </div>
              <div className="tw-flex tw-flex-row tw-flex-wrap tw-gap-6">
                <div className="tw-flex tw-flex-col tw-gap-[10px] tw-max-w-[116px]">
                  <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">Длина</p>
                  <Field name="length" type="number">
                    {({ input }) => <Input {...input} required errorStyle='tw-left-[-6px]'/>}
                  </Field>
                </div>
                <div className="tw-flex tw-flex-col tw-gap-[10px] tw-max-w-[116px]">
                  <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">Высота</p>
                  <Field name="height" type="number">
                    {({ input }) => <Input {...input} required errorStyle='tw-left-[-6px]'/>}
                  </Field>
                </div>
                <div className="tw-flex tw-flex-col tw-gap-[10px] tw-max-w-[116px]">
                  <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">Ширина</p>
                  <Field name="width" type="number">
                    {({ input }) => <Input {...input} required errorStyle='tw-left-[-6px]'/>}
                  </Field>
                </div>
                <div className="tw-flex tw-flex-col tw-gap-[10px] tw-max-w-[116px]">
                  <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">Макс. вес</p>
                  <Field name="maximum_load_weight" type="number">
                    {({ input }) => <Input {...input} required errorStyle='tw-left-[-6px]'/>}
                  </Field>
                </div>
              </div>
              <DialogActions className="tw-flex tw-flex-row tw-w-full tw-items-center tw-justify-end tw-gap-6 tw-p-0 tw-mt-[56px]">
                <Button
                  className="tw-w-fit tw-px-0 tw-text-[#666] tw-text-sm hover:tw-bg-transparent"
                  variant="text"
                  disabled={submitting}
                  onClick={() => dialog.close()}
                >
                  Отменить
                </Button>
                <Button
                  className="tw-w-fit tw-rounded-lg tw-font-semibold tw-text-sm tw-py-3 tw-px-4 disabled:tw-bg-[#83A1D9]"
                  disabled={submitting || invalid || pristine}
                  type="submit"
                  onClick={handleSubmit}
                >
                  Редактировать
                </Button>
              </DialogActions>
            </div>
          )}
        </Form>
      </div>
    </Dialog>
  );
};
