import { Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';
import moment from 'moment';
import React, { useState } from 'react';
import classes from '../range-picker/range-picker.module.sass';
import { DateRangePicker } from 'react-dates';
import { ReactComponent as ArrowDownIcon } from '../../icons/arrowDownIcon.svg';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

const RANGE_OPTIONS = {
  LAST_MONTH: 'Прошлый месяц',
  LAST_3_MONTHS: '3 месяца назад',
  LAST_6_MONTHS: '6 месяцев назад',
  LAST_YEAR: 'Прошлый год',
  ALL_TIME: 'За всё время',
};

export const DatePicker = ({ queryParams, setQueryParams, withPortal, withOptions = true }) => {
  const [isOptionOpen, setIsOptionsOpen] = useState(false);
  const options = Object.values(RANGE_OPTIONS);
  const [rangeOption, setRangeOption] = useState(RANGE_OPTIONS.LAST_MONTH);

  const handleSelectRangeOption = (option) => {
    setIsOptionsOpen(false);
    setRangeOption(option);
    switch (option) {
      case RANGE_OPTIONS.LAST_MONTH:
        setQueryParams({
          since: moment().subtract(1, 'month').startOf('hour'),
          till: moment().startOf('hour'),
        });
        return;
      case RANGE_OPTIONS.LAST_3_MONTHS:
        setQueryParams({
          since: moment().subtract(3, 'month'),
          till: moment(),
        });
        return;
      case RANGE_OPTIONS.LAST_6_MONTHS:
        setQueryParams({
          since: moment().subtract(6, 'month'),
          till: moment(),
        });
        return;
      case RANGE_OPTIONS.LAST_YEAR:
        setQueryParams({
          since: moment().subtract(1, 'year'),
          till: moment(),
        });
        return;
      case RANGE_OPTIONS.ALL_TIME:
        setQueryParams({
          since: moment().subtract(10, 'year'),
          till: moment(),
        });
        return;
      default:
        return;
    }
  };
  const [focusedInput, setFocusedInput] = useState(null);

  return (
    <div className="tw-grid tw-grid-flow-row sm:tw-grid-flow-col sm:tw-auto-cols-max tw-justify-start sm:tw-justify-end tw-gap-2">
      {withOptions && (
        <Menu as="div" className="tw-relative tw-inline-block tw-text-left">
          <div>
            <Menu.Button
              className="form-input tw-inline-flex tw-w-full tw-items-center tw-justify-center tw-text-gray-500 hover:tw-text-gray-600 tw-rounded-md tw-bg-white tw-px-4 tw-py-2 tw-text-sm tw-font-medium focus:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-white focus-visible:tw-ring-opacity-75"
              onClick={() => {
                setIsOptionsOpen((prev) => !prev);
              }}
            >
              <span>{rangeOption || 'Произвольная дата'}</span>
              <ArrowDownIcon
                className={`tw-ml-2 tw--mr-1 tw-h-5 tw-w-5 tw-transition-transform ${
                  isOptionOpen ? 'tw-rotate-180' : 'tw-rotate-0'
                }`}
              />
            </Menu.Button>
          </div>
          <Transition
            show={isOptionOpen}
            as={React.Fragment}
            enter="tw-transition tw-ease-out tw-duration-100"
            enterFrom="tw-transform tw-opacity-0 tw-scale-95"
            enterTo="tw-transform tw-opacity-100 tw-scale-100"
            leave="tw-transition tw-ease-in tw-duration-75"
            leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
            leaveTo="tw-transform tw-opacity-0 tw-scale-95"
          >
            <Menu.Items
              as="div"
              className="tw-min-w-44 tw-z-10 tw-absolute tw-top-full tw-right-0 tw-w-full tw-bg-white tw-border tw-border-gray-200 tw-py-1.5 tw-rounded tw-shadow-lg tw-overflow-hidden tw-mt-1"
            >
              {options.map((option, i) => (
                <div key={i} className="tw-font-medium tw-text-sm tw-text-gray-600">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${
                          active ? 'hover:tw-bg-gray-50' : 'tw-text-gray-900'
                        } tw-group tw-flex tw-w-full tw-items-center tw-rounded-md tw-py-1 tw-px-3`}
                        onClick={() => handleSelectRangeOption(option)}
                      >
                        <svg
                          className={classNames(
                            'tw-shrink-0 tw-mr-2 tw-fill-current tw-text-indigo-500',
                            (option !== rangeOption || !rangeOption) && 'tw-invisible'
                          )}
                          width="12"
                          height="9"
                          viewBox="0 0 12 9"
                        >
                          <path d="M10.28.28L3.989 6.575 1.695 4.28A1 1 0 00.28 5.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28.28z" />
                        </svg>
                        <span>{option}</span>
                      </button>
                    )}
                  </Menu.Item>
                </div>
              ))}
            </Menu.Items>
          </Transition>
        </Menu>
      )}
      <div className={classes.rangepicker}>
        <DateRangePicker
          withPortal={withPortal}
          inputIconPosition="after"
          customInputIcon={<ArrowDownIcon className="tw-h-5 tw-w-5" />}
          startDate={moment(queryParams.since)}
          isOutsideRange={() => false}
          displayFormat={'MMM DD, YYYY'}
          endDate={moment(queryParams.till)}
          onDatesChange={({ startDate, endDate }) => {
            setRangeOption(null);
            setQueryParams({
              since: moment(startDate),
              till: moment(endDate),
            });
          }}
          focusedInput={focusedInput}
          onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
        />
      </div>
    </div>
  );
};
