import { TabContext, TabList, TabPanel } from '@mui/lab';
import GeneralLayout from '../../components/general-layout';
import { useState } from 'react';
import { Tab } from '@mui/material';
import { UsersReport } from './components/UsersReport';
import { YandexSearcherReport } from './components/YandexReport';
import { YandexGPT } from './components/YandexGPT';

const REPORTS_LIST = [
  {
    title: 'Все организации системы',
    downloadUrl: '/api/organizations/export/all_organizations',
    documentName: 'AllOrganizations',
  },
  {
    title: 'Все пользователи системы',
    downloadUrl: '/api/admin/users/export/users_xlsx',
    documentName: 'AllUsers',
  },
];

export const Reports = () => {
  const [activeTab, setActiveTab] = useState('users');

  const handleTabChange = (e, value) => {
    setActiveTab(value);
  };

  return (
    <GeneralLayout>
      <h2 className="tw-text-[#191919] tw-font-semibold tw-font-roboto tw-text-[32px] tw-leading-[38px] tw-mb-8">
        Отчеты
      </h2>
      <div className="tw-flex tw-flex-col tw-bg-white tw-rounded-lg tw-px-6">
        <TabContext value={activeTab}>
          <div className="tw-w-full tw-border-b tw-border-b-[#DDDDDD] tw-pt-[10px] ">
            <TabList
              variant="scrollable"
              scrollButtons="auto"
              onChange={handleTabChange}
              sx={{
                '& .MuiTabs-flexContainer': {
                  gap: '24px',
                },
              }}
            >
              <Tab label="Отчеты" value="users" disableRipple className="tw-normal-case tw-px-0" />
              <Tab label="Yandex Searcher" value="yandex_searcher" disableRipple className="tw-normal-case tw-px-0" />
              <Tab label="Лимитов Yandex GPT" value="yandex_gpt" disableRipple className="tw-normal-case tw-px-0" />
            </TabList>
          </div>
          <TabPanel value="users" className="tw-p-0">
            <div className="tw-pt-4">
              {REPORTS_LIST.map((report) => (
                <UsersReport report={report} key={report.downloadUrl} />
              ))}
            </div>
          </TabPanel>
          <TabPanel value="yandex_searcher" className="tw-p-0">
            <div className="tw-pt-4">
              <YandexSearcherReport />
            </div>
          </TabPanel>
          <TabPanel value="yandex_gpt" className="tw-p-0">
            <div className="tw-pt-4">
              <YandexGPT />
            </div>
          </TabPanel>
        </TabContext>
      </div>
    </GeneralLayout>
  );
};
