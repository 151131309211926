import generator from 'generate-password';
import { CALCULATED_DIMENSHIONS_FROM } from './constant';
import moment from 'moment';

export const projectNameValidator = (name) => {
  if (name && name.length < 5) {
    return 'Название должно содержать более 4 символов';
  }
  return false;
};

export const urlValidator = (value) => {
  const error = 'Хост не валиден';
  if (!value || typeof value !== 'string') {
    return error;
  }

  const validHostnameChars = /^[a-zA-Z0-9-.]{1,253}\.?$/g;
  if (!validHostnameChars.test(value)) {
    return error;
  }

  if (value.endsWith('.')) {
    value = value.slice(0, value.length - 1);
  }

  if (value.length > 253) {
    return error;
  }

  const labels = value.split('.');

  const isValid = labels.every(function (label) {
    const validLabelChars = /^([a-zA-Z0-9-]+)$/g;

    const validLabel =
      validLabelChars.test(label) && label.length < 64 && !label.startsWith('-') && !label.endsWith('-');

    return validLabel;
  });

  return isValid ? false : error;
};

export const checkIsAdmin = (user) => {
  if (!user) return false;
  return user.role === 'admin';
};

export const generatePassword = (options = {}) => {
  const {
    length = 12,
    uppercase = false,
    numbers = false,
    // symbols = false
  } = options;

  const CHAR_SETS = {
    lowercase: 'abcdefghijklmnopqrstuvwxyz',
    uppercase: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    numbers: '0123456789',
    // symbols: '!@#$%^&*()_+~`|}{[]\\:;?><,./-='
  };

  const requiredSets = [];
  if (uppercase) requiredSets.push(CHAR_SETS.uppercase);
  if (numbers) requiredSets.push(CHAR_SETS.numbers);
  // if (symbols) requiredSets.push(CHAR_SETS.symbols);

  const minLength = requiredSets.length;
  if (length < minLength) {
    throw new Error(`Длина пароля не должна быть меньше ${minLength} символов`);
  }

  let allChars = CHAR_SETS.lowercase;
  if (uppercase) allChars += CHAR_SETS.uppercase;
  if (numbers) allChars += CHAR_SETS.numbers; 
  // if (symbols) allChars += CHAR_SETS.symbols;

  const password = [];

  for (const set of requiredSets) {
    password.push(set[Math.floor(Math.random() * set.length)]);
  }

  const remainingLength = length - password.length;
  for (let i = 0; i < remainingLength; i++) {
    password.push(allChars[Math.floor(Math.random() * allChars.length)]);
  }

  // additional suffle
  for (let i = password.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [password[i], password[j]] = [password[j], password[i]];
  }

  return password.join('');
};

export const getWordEnding1 = (word, amount) => {
  // 1 товар, 2 товара, 5 товаров
  const str = String(amount);
  const lastChar = str.slice(-1);
  if (lastChar === '1') {
    return word;
  } else if (['2', '3', '4'].includes(lastChar)) {
    return word + 'а';
  }
  return word + 'ов';
};

export const getWordEnding2 = (word, amount) => {
  // 1 секунда, 2 секунды, 5 секунд
  const str = String(amount);
  const num = Number(amount);
  const lastChar = str.slice(-1);
  if (lastChar === '1' && str !== '11') {
    return word + 'а';
  } else if (['2', '3', '4'].includes(lastChar) && (num < 12 || num >= 22)) {
    return word + 'ы';
  }
  return word;
};

export const getWordEnding3 = (word, amount) => {
  // для 1 товара, для 2 товаров
  const str = String(amount);
  const lastChar = str.slice(-1);
  if (lastChar === '1') {
    return word + 'а';
  } else {
    return word + 'ов';
  }
};

export const toCustomShort = (num) => {
  return new Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
  }).format(num);
};

export const hexToRGB = (h) => {
  let r = 0;
  let g = 0;
  let b = 0;
  if (h.length === 4) {
    r = `0x${h[1]}${h[1]}`;
    g = `0x${h[2]}${h[2]}`;
    b = `0x${h[3]}${h[3]}`;
  } else if (h.length === 7) {
    r = `0x${h[1]}${h[2]}`;
    g = `0x${h[3]}${h[4]}`;
    b = `0x${h[5]}${h[6]}`;
  }
  return `${+r},${+g},${+b}`;
};

export const formatValue = (value) =>
  Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumSignificantDigits: 3,
    notation: 'compact',
  }).format(value);

const trauncateFractionAndFormat = (parts, digits) => {
  return parts
    .map(({ type, value }) => {
      if (type !== 'fraction' || !value || value.length < digits) {
        return value;
      }

      let retVal = '';
      for (let idx = 0, counter = 0; idx < value.length && counter < digits; idx++) {
        if (value[idx] !== '0') {
          counter++;
        }
        retVal += value[idx];
      }
      return retVal;
    })
    .reduce((string, part) => string + part);
};
export const formatter = new Intl.NumberFormat('ru-RU', {
  style: 'currency',
  currency: 'RUB',
  maximumFractionDigits: 2,
});

export const formatThousands = (value) => trauncateFractionAndFormat(formatter.formatToParts(value), 2);

export const rememberAdminToReturn = (user, email, pass) => {
  const isAdmin = checkIsAdmin(user);
  if (!isAdmin) return;
  const prevUser = sessionStorage.getItem('a_user');
  if (!prevUser) {
    const login = Buffer.from(email, 'utf-8').toString('base64');
    const password = Buffer.from(pass, 'utf-8').toString('base64');
    sessionStorage.setItem('a_user', `${login} ${password}`);
  }
};

export const getProjectStatus = (value) => {
  switch (value?.report_status) {
    case 'finished':
      return (
        <div className="tw-flex tw-flex-row tw-justify-center ">
          <div
            className={
              'tw-rounded-[9px] tw-bg-[#EEFCEE] tw-py-[5px] tw-px-[10px] tw-max-w-fit tw-text-[#49CA4E] tw-text-xs'
            }
          >
            Завершено
          </div>
        </div>
      );
    case 'initial':
      return (
        <div className="tw-flex tw-flex-row tw-justify-center ">
          <div
            className={
              'tw-rounded-[9px] tw-bg-[#FFF8EC] tw-py-[5px] tw-px-[10px] tw-max-w-fit tw-text-[#FEB945] tw-text-xs'
            }
          >
            Создан
          </div>
        </div>
      );
    case 'processing':
    case 'searching':
      return (
        <div className="tw-flex tw-flex-row tw-justify-center ">
          <div
            className={
              'tw-rounded-[9px] tw-bg-[#FFF8EC] tw-py-[5px] tw-px-[10px] tw-max-w-fit tw-text-[#FEB945] tw-text-xs'
            }
          >
            В процессе
          </div>
        </div>
      );
    case 'failed':
      return (
        <div className="tw-flex tw-flex-row tw-justify-center ">
          <div
            className={
              'tw-rounded-[9px] tw-bg-[#FFEAEA] tw-py-[5px] tw-px-[10px] tw-max-w-fit tw-text-[#FF4343] tw-text-xs'
            }
          >
            Прервано
          </div>
        </div>
      );
    case 'stopped':
    case 'all_stopped':
      return (
        <div className="tw-flex tw-flex-row  tw-justify-center tw-items-center">
          <div
            className={
              'tw-rounded-[9px] tw-bg-[#FFEAEA] tw-py-[5px] tw-px-[10px] tw-max-w-fit tw-text-[#FF4343] tw-text-xs'
            }
          >
            Остановлено
          </div>
        </div>
      );
    case 'optimized':
      return (
        <div className="tw-flex tw-flex-row  tw-justify-center tw-items-center">
          <div
            className={
              'tw-rounded-[9px] tw-bg-[#FFF8EC] tw-py-[5px] tw-px-[10px] tw-max-w-fit tw-text-[#FEB945] tw-text-xs'
            }
          >
            Оптимизация
          </div>
        </div>
      );
    case 'delivery':
      return (
        <div className="tw-flex tw-flex-row  tw-justify-center tw-items-center">
          <div
            className={
              'tw-rounded-[9px] tw-bg-[#FFF8EC] tw-py-[5px] tw-px-[10px] tw-max-w-fit tw-text-[#FEB945] tw-text-xs'
            }
          >
            Расчет логистики
          </div>
        </div>
      );
    case 'screenshotting':
      return (
        <div className="tw-flex tw-flex-row  tw-justify-center tw-items-center">
          <div
            className={
              'tw-rounded-[9px] tw-bg-[#E6F1FF] tw-py-[5px] tw-px-[10px] tw-max-w-fit tw-text-[#0047FF] tw-text-xs'
            }
          >
            Создание скриншота
          </div>
        </div>
      )
    default:
      return (
        <div className="tw-flex tw-flex-row tw-justify-center ">
          <div
            className={
              'tw-rounded-[9px] tw-bg-[#EEEEEE] tw-py-[5px] tw-px-[10px] tw-max-w-fit tw-text-[#7F7F7F] tw-text-xs'
            }
          >
            Статус
          </div>
        </div>
      );
  }
};

export const isValidUrl = (url) => {
  try {
    new URL(url);
    return true;
  } catch (err) {
    return false;
  }
};

export const convertStringToURL = (value) => {
  try {
    const url = new URL(value);
    return url;
  } catch (err) {
    return false;
  }
};

export const dimenshionsFromFormatter = (calculatedFrom) => {
  try {
    return CALCULATED_DIMENSHIONS_FROM[calculatedFrom];
  } catch {
    return 'из неизвестного источника';
  }
};

export const filterSourcesByType = (sources, validTypes) => {
  return sources.filter((source) => validTypes.includes(source.type));
};

export const calculateProductOrderNumber = (index, page_num, page_size) => {
    return (page_num - 1) * page_size + index + 1
}


export const supportRequestStatusFormatter = (source) => {
  switch (source) {
    case 'accepted':
      return (
        <div className="tw-flex tw-flex-row tw-items-center tw-bg-[#FFEAEA] tw-rounded-[9px] tw-py-[5px] tw-px-[10px] tw-text-[#FF4343] tw-text-xs tw-leading-4 tw-font-medium tw-text-center tw-w-fit">
          <p>Принято</p>
        </div>
      );
    case 'in_queue':
      return (
        <div className="tw-flex tw-flex-row tw-items-center tw-bg-[#FFF8EC] tw-rounded-[9px] tw-py-[5px] tw-px-[10px] tw-text-[#DD9F00] tw-text-xs tw-leading-4 tw-font-medium tw-text-center tw-w-fit">
          <p>В работе</p>
        </div>
      );
    case 'completed':
      return (
        <div className="tw-flex tw-flex-row tw-items-center tw-bg-[#EEFCEE] tw-rounded-[9px] tw-py-[5px] tw-px-[10px] tw-text-[#49CA4E] tw-text-xs tw-leading-4 tw-font-medium tw-text-center tw-w-fit">
          <p>Завершено</p>
        </div>
      );
    default:
      return (
        <div
          className={
            'tw-flex tw-flex-row tw-items-center tw-bg-[#EEEEEE] tw-rounded-[9px] tw-py-[5px] tw-px-[10px] tw-text-[#7F7F7F] tw-text-xs tw-leading-4 tw-font-medium tw-text-center tw-w-fit'
          }
        >
          Статус
        </div>
      );
  }
};

export const getDecisionDays = (dateFrom, days) => {
  if (!days || Number.isNaN(Number(days))) {
    return 'Не указано';
  } else return moment(dateFrom).add(days, 'days').format('DD.MM.YYYY');
};

export const getChangedFormValues = (initial, current) => {
  const changed = {};
  Object.keys(current).forEach(key => {
    if (current[key] !== initial[key]) {
      changed[key] = current[key];
    }
  });
  return changed;
};