import { useCallback } from 'react';
import Button from '../../../components/button';
import { Pagination } from '../../../components/table/pagination';
import { ReactComponent as EditIcon } from '../../../icons/editIcon.svg';
import { ReactComponent as AddIcon } from '../../../icons/addIcon.svg';
import { ReactComponent as DeleteIcon } from '../../../icons/deleteIcon.svg';
import { Form } from 'react-final-form';
import classes from '../size-handbook.module.sass';
import { SearchInput } from '../../../components/input/search';
import { useDialog } from '../../../providers/dialog.provider';
import { SizesFormDialog } from './SizesFormDialog';
import { CrudTable } from '../../../components/table/CrudTable';
import {
  useAddHandbookMutation,
  useDeleteHandbookMutation,
  useEditHandbookMutation,
  useGetHandbooksQuery,
  useLazyGetHandbookQuery,
} from '../../../services';
import { ConfirmModal } from '../../../components/modal/ConfirmModal';
import { createErrorNotification, createNotification } from '../../../utils/notifications';
import { UploadExcelButtons } from './UploadExcelButtons';
import { useUrlState } from '../../../hooks/useUrlState';

export default function StandardSizesHandbook() {
  const { getQueryParam, setQueryParam } = useUrlState();

  const page_size = Number(getQueryParam('page_size')) || 10;
  const page_num = Number(getQueryParam('page_num')) || 1;
  const query = getQueryParam('query') || '';

  const { data: handbooks, isLoading } = useGetHandbooksQuery({
    endpoint: 'standard_sizes',
    limit: page_size,
    page_num,
    query,
  });

  const [createHandbook] = useAddHandbookMutation();
  const [editHandbook] = useEditHandbookMutation();
  const [deleteHandbook] = useDeleteHandbookMutation();
  const [getHandbook] = useLazyGetHandbookQuery();

  const goToPage = useCallback(
    (page) => {
      setQueryParam('page_num', page);
    },
    [setQueryParam]
  );

  const handleLimitChange = useCallback(
    (value) => {
      setQueryParam('page_size', value);
      setQueryParam('page_num', 1);
    },
    [setQueryParam]
  );

  const handleQueryChange = useCallback(
    (value) => {
      setQueryParam('query', value);
      setQueryParam('page_num', 1);
    },
    [setQueryParam]
  );

  const dialog = useDialog();

  const columns = {
    name: {
      label: 'Имя',
    },
    length: {
      label: 'Длина',
    },
    height: {
      label: 'Высота',
    },
    width: {
      label: 'Ширина',
    },
    maximum_load_weight: {
      label: 'Макс. вес',
    },
    size_units: {
      label: 'Ед. размера',
    },
    weight_units: {
      label: 'Ед. массы',
    },
  };

  const createSize = useCallback(
    async (category) => {
      try {
        await createHandbook({ endpoint: 'standart_sizes', body: category });
        createNotification('Типовой размер создан', { type: 'success', hideProgressBar: true });
      } catch (err) {
        console.error(err);
        if (err.statusCode === 409) {
          createErrorNotification('Типовой размер уже существует');
        }
      }
    },
    [createHandbook]
  );

  const editSize = useCallback(
    async (id, category) => {
      try {
        await editHandbook({ endpoint: 'standart_sizes', id, body: category });
        createNotification('Типовой размер изменён', { type: 'success', hideProgressBar: true });
      } catch (err) {
        console.error(err);
      }
    },
    [editHandbook]
  );

  const deleteSize = useCallback(
    async (id) => {
      await deleteHandbook({ endpoint: 'standard_sizes', id });
    },
    [deleteHandbook]
  );

  const showCreateSizeForm = useCallback(() => {
    dialog.open(
      <SizesFormDialog
        title={'Добавление типового размера'}
        value={{
          name: '',
          length: '',
          height: '',
          width: '',
          maximum_load_weight: '',
          size_units: '',
          weight_units: '',
        }}
        onSubmit={createSize}
      />
    );
  }, [dialog, createSize]);

  const showEditSizeForm = useCallback(
    async ({ id }) => {
      await getHandbook({ endpoint: 'standard_sizes', id })
        .unwrap()
        .then((category) => {
          dialog.open(
            <SizesFormDialog value={category} title={'Редактирование типового размера'} onSubmit={editSize} />
          );
        });
    },

    [dialog, editSize, getHandbook]
  );
  const askConfirmationDelete = useCallback(
    (category) => {
      dialog.open(
        <ConfirmModal
          value={category}
          title={`Вы уверены, что хотите удалить ${category.name}?`}
          onAgree={deleteSize}
        />
      );
    },
    [deleteSize, dialog]
  );

  const crudButtons = (entity) => {
    return (
      <div className="tw-flex tw-flex-row tw-border-b-0">
        <Button
          variant="text"
          className="tw-p-0 tw-max-w-6 hover:tw-bg-inherit"
          onClick={() => showEditSizeForm(entity)}
        >
          <EditIcon />
        </Button>
        <Button
          variant="text"
          className="tw-p-0 tw-max-w-6 hover:tw-bg-inherit"
          onClick={() => askConfirmationDelete(entity)}
        >
          <DeleteIcon />
        </Button>
      </div>
    );
  };

  return (
    <>
      <div className="tw-bg-white tw-py-[25px] tw-px-[24px] tw-rounded-lg">
        <div className="tw-flex lg:tw-flex-row tw-flex-col tw-justify-between tw-gap-4">
          <Form onSubmit={() => {}}>
            {() => (
              <SearchInput
                className={` tw-max-w-[312px] ${classes.searchInput}`}
                onSearchChange={handleQueryChange}
                name="search"
                placeholder="Найти типовой размер"
                type="text"
                required
                size="small"
              />
            )}
          </Form>
          <div className="tw-flex lg:tw-flex-row tw-flex-col lg:tw-items-center md:tw-items-start lg:tw-gap-4 md:tw-gap-0 tw-flex-wrap">
            <UploadExcelButtons />
            <Button variant="text" className="tw-p-2 tw-w-[fit-content]" onClick={() => showCreateSizeForm()}>
              <AddIcon className="tw-mr-[4px]" />
              Добавить типовой размер
            </Button>
          </div>
        </div>
        {!isLoading ? (
          <CrudTable rows={handbooks?.data} entityColumns={columns} crudButtons={crudButtons} />
        ) : (
          <div className="tw-flex tw-flex-row tw-items-center tw-justify-center tw-mt-10">
            <div
              class="tw-inline-block tw-h-8 tw-w-8 tw-animate-spin tw-rounded-full tw-border-4 tw-border-solid tw-border-[#0844B4] tw-border-r-transparent tw-align-[-0.125em] tw-text-primary tw-motion-reduce:animate-[spin_1.5s_linear_infinite]"
              role="status"
            />
          </div>
        )}
      </div>
      <Pagination
        rowsPerPage={page_size}
        count={handbooks?.count}
        page={page_num}
        onPageChange={goToPage}
        onLimitChange={handleLimitChange}
      />
    </>
  );
}
