import { useCallback, useState, useMemo } from 'react';
import Button from '../../../components/button';
import { DatePicker } from '../../../components/date-picker';
import moment from 'moment';
import { useUrlState } from '../../../hooks/useUrlState';
import { Form } from 'react-final-form';
import { SearchInput } from '../../../components/input/search';
import { ReactComponent as DeleteIcon } from '../../../icons/deleteIcon.svg';
import { ReactComponent as DisabledDeleteIcon } from '../../../icons/delete.svg';
import { ReactComponent as EditIcon } from '../../../icons/editIcon.svg';
import { ReactComponent as DownloadIcon } from '../../../icons/downloadIcon.svg';
import {
  useAddStandartSizeToHandbookMutation,
  useDeleteStandardSizesMutation,
  useGetStandartSizeReportQuery,
} from '../../../services';
import Table from '../../../components/table';
import { Pagination } from '../../../components/table/pagination';
import { CircularProgress, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useDialog } from '../../../providers/dialog.provider';
import { SizeEditModal } from './SizeEditModal';
import { downloadFile } from '../../../utils/request';
import { createErrorNotification } from '../../../utils/notifications';
import { ConfirmModal } from '../../../components/modal/ConfirmModal';
import { ReactComponent as CheckIcon } from '../../../icons/checkedIcon.svg';
import { ReactComponent as ErrorIcon } from '../../../icons/errorIcon.svg';
import { ReactComponent as UndoIcon } from '../../../icons/undoIcon.svg';
import { Tooltip } from '../../../components/tooltip/Tooltip';

function useSelection(initialSelections = { add: [], delete: [] }) {
  const [selected, setSelected] = useState(initialSelections);

  const isSelected = useCallback((id, action) => (selected[action] || []).includes(id), [selected]);

  const setSelection = useCallback((id, action) => {
    setSelected((prev) => {
      let newAdd = [...prev.add];
      let newDelete = [...prev.delete];

      if (action === 'add') {
        if (!newAdd.includes(id)) {
          newAdd.push(id);
        }

        newDelete = newDelete.filter((item) => item !== id);
      } else if (action === 'delete') {
        if (!newDelete.includes(id)) {
          newDelete.push(id);
        }

        newAdd = newAdd.filter((item) => item !== id);
      }
      return { add: newAdd, delete: newDelete };
    });
  }, []);

  const setAllSelection = useCallback((rows, action) => {
    const ids = rows.map((row) => row.id);
    setSelected((prev) => {
      let newAdd = [...prev.add];
      let newDelete = [...prev.delete];

      if (action === 'add') {
        ids.forEach((id) => {
          if (!newAdd.includes(id)) {
            newAdd.push(id);
          }

          newDelete = newDelete.filter((item) => item !== id);
        });
      } else if (action === 'delete') {
        ids.forEach((id) => {
          if (!newDelete.includes(id)) {
            newDelete.push(id);
          }

          newAdd = newAdd.filter((item) => item !== id);
        });
      }
      return { add: newAdd, delete: newDelete };
    });
  }, []);

  const clearSelection = useCallback(() => {
    setSelected({ add: [], delete: [] });
  }, []);

  return { selected, isSelected, setSelection, setAllSelection, clearSelection };
}

export const AddProductTable = () => {
  const [queryParams, setQueryParams] = useState({
    since: moment().subtract(1, 'month'),
    till: moment(),
  });
  const dialog = useDialog();

  const { selected, isSelected, setSelection, clearSelection, setAllSelection } = useSelection();

  const { getQueryParam, setQueryParam } = useUrlState();
  const page_size = getQueryParam('page_size') || 10;
  const page_num = getQueryParam('page_num') || 1;
  const query = getQueryParam('query') || '';

  const { data: standardSizeReport } = useGetStandartSizeReportQuery({
    page_size,
    page_num,
    query,
  });

  const [addStandardSizes, { isLoading: isAdding }] = useAddStandartSizeToHandbookMutation();
  const [deleteStandardSizes, { isLoading: isDeleting }] = useDeleteStandardSizesMutation();

  const rows = useMemo(() => standardSizeReport?.data || [], [standardSizeReport?.data]);

  const handleQueryChange = useCallback(
    (value) => {
      setQueryParam('query', value);
      setQueryParam('page_num', 1);
    },
    [setQueryParam]
  );

  const goToPage = useCallback(
    (page) => {
      setQueryParam('page_num', page);
    },
    [setQueryParam]
  );

  const handleLimitChange = useCallback(
    (value) => {
      setQueryParam('page_size', value);
      setQueryParam('page_num', 1);
    },
    [setQueryParam]
  );

  const handleDeleteStandardSize = useCallback(
    async (id) => {
      await deleteStandardSizes({
        selectedSizes: [id],
      });
    },
    [deleteStandardSizes]
  );

  const showEditModal = useCallback(
    (value) => {
      dialog.open(<SizeEditModal value={value} />);
    },
    [dialog]
  );

  const showConfirmDeleteOneModal = useCallback(
    (entity) => {
      dialog.open(
        <ConfirmModal
          title={'Вы уверены, что хотите удалить товар?'}
          onAgree={handleDeleteStandardSize}
          value={entity}
        />
      );
    },
    [dialog, handleDeleteStandardSize]
  );

  const headerSelectionValue = useMemo(() => {
    if (rows.length === 0) return '';
    const allAdd = rows.every((row) => isSelected(row.id, 'add'));
    const allDelete = rows.every((row) => isSelected(row.id, 'delete'));
    if (allAdd) return 'add';
    if (allDelete) return 'delete';
    return '';
  }, [rows, isSelected]);

  const handleApprove = async () => {
    try {
      if (selected.add.length > 0) {
        await addStandardSizes({
          selectedSizes: selected.add,
        });
      }
      if (selected.delete.length > 0) {
        await deleteStandardSizes({
          selectedSizes: selected.delete,
        });
      }
    } catch (err) {
      console.log(err)
      console.log(err?.data?.message)
      createErrorNotification(err?.data?.message ?? 'Произошла ошибка при применении изменений');
    } finally {
      clearSelection();
    }
  };

  const columns = useMemo(
    () => ({
      id: {
        label: '',
        renderHeaderCell: () => (
          <RadioGroup
            className="tw-w-flex tw-flex-row tw-gap-2"
            name="header-selection"
            value={headerSelectionValue}
            onChange={(event) => {
              const newValue = event.target.value;
              setAllSelection(rows, newValue);
            }}
          >
            <FormControlLabel
              value="add"
              className="tw-m-0 "
              control={
                <Radio
                  size="small"
                  checkedIcon={<CheckIcon />}
                  sx={{
                    color: '#0BD90B',
                  }}
                />
              }
            />
            <FormControlLabel
              value="delete"
              className="tw-m-0 "
              control={
                <Radio
                  size="small"
                  checkedIcon={<ErrorIcon />}
                  sx={{
                    color: '#C12026',
                  }}
                />
              }
            />
          </RadioGroup>
        ),
        renderBodyCell: (value) => {
          const radioGroupName = `action-${value.id}`;
          const currentValue = isSelected(value.id, 'add') ? 'add' : isSelected(value.id, 'delete') ? 'delete' : '';

          return (
            <RadioGroup
              className="tw-w-flex tw-flex-row tw-gap-2"
              name={radioGroupName}
              value={currentValue}
              onChange={(event) => {
                const newValue = event.target.value;
                setSelection(value.id, newValue);
              }}
            >
              <FormControlLabel
                value="add"
                className="tw-m-0 "
                control={
                  <Radio
                    size="small"
                    checkedIcon={<CheckIcon />}
                    sx={{
                      color: '#0BD90B',
                    }}
                  />
                }
              />
              <FormControlLabel
                value="delete"
                className="tw-m-0 "
                control={
                  <Radio
                    size="small"
                    checkedIcon={<ErrorIcon />}
                    sx={{
                      color: '#C12026',
                    }}
                  />
                }
              />
            </RadioGroup>
          );
        },
      },
      name: {
        label: 'Наименование в источнике',
        renderBodyCell: (value) => (
          <div className="tw-max-w-[250px]">
            <Tooltip title={value?.name}>
              <p className="tw-max-w-[249px] tw-truncate tw-block">{value?.name}</p>
            </Tooltip>
          </div>
        ),
      },
      url: {
        label: 'URL',
        renderBodyCell: (value) => (
          <a
            href={value?.url || '#'}
            target="_blank"
            rel="noreferrer"
            className="tw-text-[#134B98] tw-no-underline tw-text-sm tw-font-medium tw-leading-[18px]"
          >
            Ссылка
          </a>
        ),
      },
      length: {
        label: 'Габариты (мм.)',
        renderBodyCell: (value) => {
          return <p>{`${value?.length}x${value?.width}x${value?.height}`}</p>;
        },
      },
      maximum_load_weight: { label: 'Макс. вес (гр.)' },
      actions: {
        label: '',
        renderBodyCell: (value) => (
          <div className="tw-flex tw-flex-row tw-items-center tw-gap-3">
            <Button
              className="tw-w-fit tw-p-0 hover:tw-bg-transparent"
              variant="text"
              onClick={() => showEditModal(value)}
            >
              <EditIcon />
            </Button>
            <Button
              className="tw-w-fit tw-p-0 hover:tw-bg-transparent"
              variant="text"
              onClick={() => showConfirmDeleteOneModal(value)}
            >
              <DeleteIcon />
            </Button>
          </div>
        ),
      },
    }),
    [headerSelectionValue, setAllSelection, rows, isSelected, setSelection, showEditModal, showConfirmDeleteOneModal]
  );

  const handleDownloadFile = async () => {
    try {
      await downloadFile(
        `/api/admin/standard_size_report/export?start_date=${moment(queryParams.since).format(
          'DD.MM.YYYY'
        )}&end_date=${moment(queryParams.till).format('DD.MM.YYYY')}`,
        `Отчет по периоду ${moment(queryParams.since).format('DD.MM.YYYY')} - ${moment(queryParams.till).format(
          'DD.MM.YYYY'
        )}.xlsx`
      );
    } catch (err) {
      createErrorNotification('Произошла ошибка при скачивании файла');
      console.error(err);
    }
  };

  return (
    <div className="tw-flex tw-flex-col tw-gap-[26px] tw-w-full">
      <div className="tw-flex tw-flex-row tw-items-center tw-justify-between tw-w-full">
        <div className="tw-flex tw-flex-row tw-items-center tw-gap-[22px]">
          <Button
            className="tw-w-fit tw-flex tw-flex-row tw-gap-1 tw-items-center tw-px-4 tw-py-3 tw-rounded-lg tw-text-sm disabled:tw-bg-[#83A1D9] tw-transition-colors"
            disabled={(selected.add.length === 0 && selected.delete.length === 0) || isAdding || isDeleting}
            onClick={handleApprove}
          >
            {(isAdding || isDeleting) && (
                <CircularProgress
                  className="tw-text-white"
                  sx={{
                    height: '14px !important',
                    width: '14px !important',
                  }}
                />
              )}
            Применить
          </Button>
          <div className="tw-flex tw-flex-col tw-gap-[10px]">
            <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
              <CheckIcon />{' '}
              <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px]">Добавить в справочник типовых размеров</p>
            </div>
            <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
              <ErrorIcon /> <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px]">Удалить товары из отчета</p>
            </div>
          </div>
        </div>
        <div className="tw-flex tw-flex-row tw-items-center tw-gap-4">
          <Button
            variant="text"
            onClick={handleDownloadFile}
            className="tw-w-fit hover:tw-bg-transparent tw-flex tw-flex-row tw-items-center tw-gap-1 tw-text-[#134B98] tw-font-medium tw-p-0"
          >
            <DownloadIcon />
            Выгрузить данные по периоду
          </Button>
          <DatePicker queryParams={queryParams} setQueryParams={setQueryParams} withOptions={false} />
        </div>
      </div>
      <div className="tw-flex tw-flex-col tw-gap-8">
        <div className="tw-flex tw-flex-col tw-bg-white tw-rounded-lg">
          <div className="tw-flex tw-flex-row tw-items-center tw-gap-[22px] tw-px-6 tw-pt-[25px] tw-pb-4">
            <Button
              variant="text"
              className="tw-group tw-w-fit tw-p-2 tw-rounded-lg tw-border tw-transition-colors tw-border-[#134B98] tw-border-solid disabled:tw-border-[#83A1D9]"
              onClick={clearSelection}
              disabled={selected.add.length === 0 && selected.delete.length === 0}
            >
              <Tooltip title="Отменить действие">
                <UndoIcon className="tw-fill-[#134B98] group-disabled:tw-fill-[#83A1D9]" />
              </Tooltip>
            </Button>
            <Form onSubmit={() => {}}>
              {() => (
                <SearchInput
                  className="tw-max-w-[312px]"
                  onSearchChange={handleQueryChange}
                  name="search"
                  placeholder="Найти типовой размер"
                  type="text"
                  required
                  size="small"
                />
              )}
            </Form>
          </div>
          <Table columns={columns} rows={rows} />
        </div>
        <Pagination
          rowsPerPage={Number(page_size)}
          count={standardSizeReport?.count}
          page={Number(page_num)}
          onPageChange={goToPage}
          onLimitChange={handleLimitChange}
        />
      </div>
    </div>
  );
};
