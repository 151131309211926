import { request } from '../utils/request';
import { Api, onQueryStartedErrorHandler } from './api-client';

export const handbookAPIs = {
  getHandbooks: (endpoint, limit = 10, page_num = 1, query = '') =>
    request(`/api/admin/catalogs/${endpoint}?page_size=${limit}&page_num=${page_num}&query=${query}`, {
      method: 'GET',
    }),
  addHandbook: (endpoint, body) =>
    request(`/api/admin/catalogs/${endpoint}`, {
      method: 'POST',
      body,
    }),
  deleteHandbook: (endpoint, id) =>
    request(`/api/admin/catalogs/${endpoint}/${id}`, {
      method: 'DELETE',
    }),
  editHandbook: (endpoint, id, body) =>
    request(`/api/admin/catalogs/${endpoint}/${id}`, {
      method: 'PATCH',
      body,
    }),
  getHandbook: (endpoint, id) =>
    request(`/api/admin/catalogs/${endpoint}/${id}`, {
      method: 'GET',
    }),
};

export const newHandbookAPIs = Api.injectEndpoints({
  endpoints: (builder) => ({
    uploadSizeExcel: builder.mutation({
      query: (file) => ({
        url: '/api/admin/catalogs/standard_sizes/upload_file',
        method: 'POST',
        body: file,
      }),
      invalidatesTags: ['Handbooks'],
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Типовые размеры успешно загружены');
      },
    }),
    getHandbooks: builder.query({
      query: ({ endpoint, limit = 10, page_num = 1, query = '' }) => ({
        url: `/api/admin/catalogs/${endpoint}`,
        params: { page_size: limit, page_num, query },
        method: 'GET',
      }),
      providesTags: ['Handbooks', 'Handbook'],
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при получении справочника');
      },
    }),
    addHandbook: builder.mutation({
      query: ({ endpoint, body }) => ({
        url: `/api/admin/catalogs/${endpoint}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Handbooks', 'Handbook'],
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при добавлении записи в справочник');
      },
    }),
    deleteHandbook: builder.mutation({
      query: ({ endpoint, id }) => ({
        url: `/api/admin/catalogs/${endpoint}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Handbooks', 'Handbook'],
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при удалении записи в справочнике');
      },
    }),
    editHandbook: builder.mutation({
      query: ({ endpoint, id, body }) => ({
        url: `/api/admin/catalogs/${endpoint}/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Handbooks', 'Handbook'],
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при изменении записи в справочнике');
      },
    }),
    getHandbook: builder.query({
      query: ({ endpoint, id }) => ({
        url: `/api/admin/catalogs/${endpoint}/${id}`,
        method: 'GET',
      }),
      providesTags: ['Handbook'],
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при получении записи в справочнике');
      },
    }),
    getStandartSizeReport: builder.query({
      query: ({ page_size, page_num, query }) => ({
        url: '/api/admin/standard_size_report',
        params: { page_num, page_size, query },
        method: 'GET',
      }),
      providesTags: ['StandardSizeReport'],
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при получении товаров');
      },
    }),
    addStandartSizeToHandbook: builder.mutation({
      query: ({ selectedSizes }) => ({
        url: '/api/admin/standard_size_report/add',
        method: 'POST',
        body: {
          standard_sizes_ids: selectedSizes,
        },
      }),
      invalidatesTags: ['StandardSizeReport', 'Handbooks'],
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при переносе товаров в справочник');
      },
    }),
    deleteStandardSizes: builder.mutation({
      query: ({ selectedSizes }) => ({
        url: '/api/admin/standard_size_report/delete',
        method: 'POST',
        body: {
          standard_sizes_ids: selectedSizes,
        },
      }),
      invalidatesTags: ['StandardSizeReport'],
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при удалении товаров');
      },
    }),
    getStandardSize: builder.query({
      query: (id) => ({
        url: `/api/admin/catalogs/standard_size/${id}`,
        method: 'GET',
      }),
      providesTags: ['StandardSize'],
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при получении записи');
      },
    }),
    deleteStandardSize: builder.mutation({
      query: ({ids}) => ({
        url: `/api/admin/standard_size_report/delete`,
        method: 'POST',
        body: {
          standard_sizes_ids: ids
        }
      }),
      invalidatesTags: ['StandardSizeReport'],
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при удалении товара');
      },
    }),
    updateStandardSize: builder.mutation({
      query: ({ id, body }) => ({
        url: `/api/admin/standard_size_report/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['StandardSize', 'StandardSizeReport'],
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при изменении товара');
      },
    }),
  }),
});

export const {
  useUploadSizeExcelMutation,
  useGetHandbooksQuery,
  useAddHandbookMutation,
  useDeleteHandbookMutation,
  useGetHandbookQuery,
  useEditHandbookMutation,
  useLazyGetHandbookQuery,
  useGetStandartSizeReportQuery,
  useAddStandartSizeToHandbookMutation,
  useDeleteStandardSizesMutation,
  useUpdateStandardSizeMutation,
  useGetStandardSizeQuery,
} = newHandbookAPIs;
