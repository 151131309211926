import Button from '../../../components/button';
import { ReactComponent as ExcelIcon } from '../../../icons/excel.svg';
import { ReactComponent as DocumentIcon } from '../../../icons/documentIcon.svg';
import { Field, Form } from 'react-final-form';
import { createErrorNotification } from '../../../utils/notifications';
import { EXCEL_MIME_TYPES } from '../../../utils/constant';
import { useCallback } from 'react';
import { useUploadSizeExcelMutation } from '../../../services';
import { downloadFile } from '../../../utils/request';

export const UploadExcelButtons = () => {
  const [uploadFile] = useUploadSizeExcelMutation();

  const validate = (values) => {
    const errors = {};

    if (!values.file || values.file.length === 0) {
      errors.file = 'Файл обязателен';
    } else if (values.file.length > 1) {
      errors.file = 'Можно выбрать только один файл';
      createErrorNotification('Можно выбрать только один файл');
    } else {
      const file = values.file[0];

      if (!EXCEL_MIME_TYPES.includes(file.type)) {
        errors.file = 'Допустимы только файлы .xls или .xlsx';
        createErrorNotification('Допустимы только файлы .xls или .xlsx');
      }
    }
    return errors;
  };

  const handleDownloadSample = useCallback(async () => {
    await downloadFile('/api/admin/catalogs/standard_sizes/file_shema', 'Пример.xlsx').catch((err) => {
      createErrorNotification('Произошла ошибка при скачивании примера Excel');
    });
  }, []);

  const handleUploadFile = useCallback(
    async (values) => {
      const selectedFile = values.file[0];

      const file = new FormData();
      file.append('file', selectedFile);
      await uploadFile(file)
        .unwrap()
        .catch((err) => {
          createErrorNotification('Произошла ошибка при загрузке Excel');
        });
    },
    [ uploadFile]
  );

  return (
    <div className="tw-flex lg:tw-flex-row tw-flex-col lg:tw-items-center tw-items-start lg:tw-gap-2 tw-flex-wrap">
      <Button
        className="tw-w-fit tw-p-2 tw-flex tw-flex-row tw-items-center tw-gap-[2px]"
        variant="text"
        onClick={handleDownloadSample}
      >
        <DocumentIcon />
        <p className="tw-text-[#0844B4] tw-text-sm tw-leading-[18px] tw-font-semibold">Пример excel файла</p>
      </Button>
      <Form onSubmit={handleUploadFile} validate={validate}>
        {({ handleSubmit }) => {
          return (
            <form onChange={handleSubmit}>
              <Field name="file">
                {({ input }) => (
                  <Button
                    variant="text"
                    className="tw-w-fit tw-flex tw-flex-row tw-items-center tw-gap-[2px] lg:tw-p-0 tw-p-2 hover:tw-bg-transparent"
                  >
                    <label className="tw-flex tw-flex-row tw-items-center tw-gap-[2px]">
                      <ExcelIcon />
                      <p className="tw-text-[#0844B4] tw-text-sm tw-leading-[18px] tw-font-semibold">
                        Загрузить размеры
                      </p>
                      <input
                        type="file"
                        name="file"
                        multiple={false}
                        accept=".xlsx, .xls"
                        onChange={(e) => input.onChange(e.target.files)}
                        className="tw-hidden"
                      />
                    </label>
                  </Button>
                )}
              </Field>
            </form>
          );
        }}
      </Form>
    </div>
  );
};
