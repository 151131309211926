import { Badge, useMediaQuery } from '@mui/material';
import { Popover, Transition } from '@headlessui/react';
import { ReactComponent as NotificationIcon } from '../../../icons/notificationIcon.svg';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useGetNotificationsForUsersQuery } from '../../../services/adminAPIs';
import { useMemo } from 'react';

function NotificationDropdown(){
  const mobile = useMediaQuery('(max-width: 427px)');
  const { data } = useGetNotificationsForUsersQuery({});

  const notificationsToShow = useMemo(() => {
    return data?.data.length > 0 ? data.data.slice(0, 4) : [];
  }, [data]);

  return (
    <div className="tw-relative tw-inline-flex">
      {!mobile && (
        <Popover>
          {({ open }) => (
            <>
              {open && (
                <div className="tw-fixed tw-inset-0 tw-bg-black tw-opacity-50 tw-z-[1]" />
              )}
              <Popover.Button className="tw-inline-flex tw-justify-center tw-items-center tw-group tw-outline-0 tw-relative tw-z-10 focus-visible:tw-outline-none">
                <span>
                  <NotificationIcon className="tw-cursor-pointer" />
                </span>
              </Popover.Button>
              <Transition
                enter="tw-transition tw-ease-out tw-duration-200 tw-transform"
                enterFrom="tw-opacity-0 tw--translate-y-2"
                enterTo="tw-opacity-100 tw-translate-y-0"
                leave="tw-transition tw-ease-out tw-duration-200"
                leaveFrom="tw-opacity-100"
                leaveTo="tw-opacity-0"
                className={' tw-z-[9] tw-relative'}
              >
                <Popover.Panel className={'tw-z-0'}>
                  <div className="tw-origin-top-right tw-z-[9] tw-max-w-[343px] tw-min-w-[343px] tw-w-full tw-absolute tw-top-[10px] tw-right-0 tw-bg-white tw-rounded-lg tw-shadow-lg tw-overflow-hidden tw-mt-[-0.05rem]">
                    <div className='tw-px-4 tw-py-6'>
                      <p className='tw-font-medium tw-text-[#191919] tw-text-base tw-leading-[20px] tw-mb-4'>
                        Уведомления
                      </p>
                      <div className="tw-divide-y">
                        {notificationsToShow.length > 0 ? (
                          notificationsToShow.map((notification) => (
                          <div key={notification.id}>
                            <p className='tw-text-sm tw-text-[#191919] tw-font-normal tw-leading-[18px] tw-mb-1 tw-mt-4 tw-truncate'>
                              <Badge
                                variant='dot' sx={{
                                '& .MuiBadge-dot': {
                                  backgroundColor: notification.viewed ? '#999999' : '#C12026'
                                },
                                margin: '8px'
                              }} />
                              {notification.text}</p>
                            <p
                              className='tw-text-xs tw-text-[#666] tw-font-normal tw-leading-[16px] tw-mb-3'>{moment(`${notification.start_date} ${notification.start_time}`).format('DD.MM.YYYY HH:mm')}</p>
                          </div>
                        ))
                        ) : (<p className='tw-text-xs tw-text-[#666] tw-mt-2'>Уведомления отсутствуют</p>)}
                      </div>
                      {data?.data.length > 0 && (
                        <Link
                        to='/user/notifications'
                        className='tw-text-[#134B98] hover:tw-text-[#0A50D4] tw-font-medium  d-flex ms-auto text-decoration-none tw-leading-[18px] tw-mt-4'
                      >
                        Смотреть все
                      </Link>)}
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      )}

      {mobile && (
        <>
          <div
            className='tw-inline-flex tw-justify-center tw-items-center tw-group tw-outline-0'
          >
            <span>
              <NotificationIcon className="tw-cursor-pointer" />
            </span>
          </div>
        </>
      )}
    </div>
  )
}

export default NotificationDropdown;