import { useState } from 'react';
import GeneralLayout from '../../components/general-layout';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab } from '@mui/material';
import StandardSizesHandbook from './components/StandardSizesHandbook';
import { AddProductTable } from './components/AddProductsTable';
import { useUrlState } from '../../hooks/useUrlState';

export default function SizeHandbook() {
  const [activeTab, setActiveTab] = useState(1);
  const {clearParams} = useUrlState()

  const handleTabChange = (e, value) => {
    clearParams()
    setActiveTab(value);
  };

  return (
    <GeneralLayout>
      <h1 className="tw-text-[2rem] tw-font-semibold tw-leading-[2.375rem] tw-text-[#191919] tw-mb-8">
        Справочник типовых размеров
      </h1>
      <TabContext value={activeTab}>
        <TabList
          onChange={handleTabChange}
          sx={{
            '& .MuiTabs-flexContainer': {
              gap: '24px',
              overflow: 'auto',
            },
          }}
        >
          <Tab value={1} disableRipple className="tw-normal-case tw-px-0" label="Справочник типовых размеров" />
          <Tab value={2} disableRipple className="tw-normal-case tw-px-0" label="Товары для добавления" />
        </TabList>
        <div className="tw-w-full tw-mt-6">
          <TabPanel value={1} className="tw-p-0">
            <StandardSizesHandbook />
          </TabPanel>
          <TabPanel value={2} className="tw-p-0">
            <AddProductTable />
          </TabPanel>
        </div>
      </TabContext>
    </GeneralLayout>
  );
}
