import { Field, Form } from 'react-final-form';
import { TextArea } from '../../components/input/TextArea';
import Button from '../../components/button';
import { Dialog } from '@mui/material';
import { useDialog } from '../../providers/dialog.provider';
import { DatePickerUniversal } from '../../components/date-picker/datePickerUniversal/DatePickerUniversal';
import { TimePicker } from '../../components/date-picker/timePicker/TimePicker';
import moment from 'moment';
import { TIMEZONE } from '../../utils/constant';


export const ModalNotification = ({handleSubmit, title, initialValues, btnSubmit = 'Создать'}) => {
  const dialog = useDialog();

  const validate = (values) => {
    const errors = {};

    if (!values.start_date) {
      errors.start_date = "Не указано";
    }

    if (!values.end_date) {
      errors.end_date = "Не указано";
    }

    if (!values.start_time) {
      errors.start_time = "Не указано";
    }

    if (!values.end_time) {
      errors.end_time = "Не указано";
    }

    if (values.start_date && values.start_time && values.end_date && values.end_time) {
      const startDateTime = moment(`${values.start_date} ${values.start_time}`, "YYYY-MM-DD HH:mm");
      const endDateTime = moment(`${values.end_date} ${values.end_time}`, "YYYY-MM-DD HH:mm");

      if (endDateTime.isBefore(startDateTime)) {
        errors.end_time = "Укажите дату и время завершения корректно";
      }
    }

    return errors;
  };

  return(
    <Dialog
      open={dialog.visibility}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "8px",
          maxWidth: "623px",
          width: "100%",
          margin: 0,
        },
      }}
    >
      <div className="tw-flex tw-flex-col tw-p-6 tw-bg-white">
        <div>
          <span className="tw-text-xs tw-text-[#0A50D4]">*Время оповещения указывается по часовому поясу GMT+5</span>
          <h4 className="tw-leading-5 tw-mb-8 tw-font-semibold tw-text-base">
            {title}
          </h4>
        </div>
        <div className="tw-flex tw-flex-row tw-w-full tw-items-center tw-justify-end tw-gap-6">
        <Form
            onSubmit={handleSubmit}
            validate={validate}
            initialValues={initialValues}
            subscription={{ submitting: true, pristine: true, values: true }}
          >
            {({ handleSubmit, form }) => {
              const { values, pristine } = form.getState();
              const isDisabled = !values.text || pristine;

              return (
                <form onSubmit={handleSubmit} className="tw-w-full tw-flex tw-flex-col tw-h-full">
                  <div className="tw-w-full">
                    <div className="tw-flex tw-pb-6 tw-relative">
                      <div className="tw-mr-2">
                        <div className="tw-mb-[10px] tw-font-medium tw-text-sm">Дата начала*</div>
                        <Field name="start_date">
                          {({ input, meta }) => (
                            <>
                              <DatePickerUniversal {...input} />
                              <div>
                                {meta.error && meta.touched && <span
                                  className="tw-text-[#FF4343] tw-absolute tw-bottom-0 tw-left-0 tw-mt-2">{meta.error}</span>}
                              </div>
                            </>
                          )}
                        </Field>
                      </div>
                      <div>
                        <div className="tw-mb-[10px] tw-font-medium tw-text-sm">Время начала*</div>
                        <Field name="start_time">
                          {({ input, meta }) => (
                            <>
                              <TimePicker {...input} date={values.start_date} setTimezone={TIMEZONE.GMT5} />
                              <div>
                                {meta.error && meta.touched && <span
                                  className="tw-text-[#FF4343] tw-absolute tw-bottom-0 tw-left-0 tw-mt-2">{meta.error}</span>}
                              </div>
                            </>
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className="tw-flex tw-pb-6 tw-relative">
                      <div className="tw-mr-2">
                        <div className="tw-mb-[10px] tw-font-medium tw-text-sm">Дата завершения*</div>
                        <Field name="end_date">
                          {({ input, meta }) => (
                            <>
                              <DatePickerUniversal {...input} />
                              <div>
                                {meta.error && meta.touched && <span
                                  className="tw-text-[#FF4343] tw-absolute tw-bottom-0 tw-left-0 tw-mt-2">{meta.error}</span>}
                              </div>
                            </>
                          )}
                        </Field>
                      </div>
                      <div>
                        <div className="tw-mb-[10px] tw-font-medium tw-text-sm">Время завершения*</div>
                        <Field name="end_time">
                          {({ input, meta }) => (
                            <>
                              <TimePicker {...input} date={values.end_date} setTimezone={TIMEZONE.GMT5}/>
                              <div>
                                {meta.error && meta.touched && <span
                                  className="tw-text-[#FF4343] tw-absolute tw-bottom-0 tw-left-0 tw-mt-2">{meta.error}</span>}
                              </div>
                            </>
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className="tw-mb-10">
                      <div className="tw-mb-[10px] tw-font-medium tw-text-sm">Текст оповещения*</div>
                      <Field name="text">
                        {({ input }) => (
                          <TextArea
                            {...input}
                            required
                            multiline
                            rows={6}
                            fullWidth
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className="tw-flex tw-justify-end tw-mt-4">
                    <Button
                      variant="text"
                      className="tw-mr-[20px] tw-text-[#666666] tw-leading-[18px] tw-w-fit tw-text-sm tw-p-0 hover:tw-bg-transparent"
                      onClick={() => dialog.close()}
                    >
                      Отменить
                    </Button>
                    <Button
                      disabled={isDisabled}
                      className="tw-w-fit tw-rounded-lg tw-px-4 tw-py-3 tw-font-medium"
                      type="submit"
                    >
                      {btnSubmit}
                    </Button>
                  </div>
                </form>
              );
            }}
          </Form>
        </div>
      </div>
    </Dialog>
  )
}