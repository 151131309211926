import React, { useCallback, useEffect, useState } from 'react';
import { useGetProductsQuery } from '../../../../services';
import { Product } from './Product';
import { Pagination } from '../../../../components/table/pagination';
import { ProductFilter } from '../ProductFilter/ProductFilter';
import { useLocation } from 'react-router-dom';
import { ProductSkeleton } from '../Skeletons/ProductSkeleton';
import { ReactComponent as ToggleDetailedIcon } from '../../../../icons/toggleDetailed.svg';
import { ReactComponent as TogglePlainIcon } from '../../../../icons/togglePlain.svg';
import { ProductsTable } from './ProductsTable';
import { useScrollRestoration } from '../../../../hooks/useScrollRestoration.js';
import { useUrlState } from '../../../../hooks/useUrlState.js';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { FILTER_PRESETS, FILTER_PRESET_LABELS } from '../../../../utils/constant.js';
import { ScreenshotsMaker } from '../Screenshots/ScreenshotsMaker.jsx';
import { calculateProductOrderNumber } from '../../../../utils/common.js';
import { useSelector } from 'react-redux';
import { isReportFinished } from '../../../../store/selectors/organization.js';

const DISPLAY_VARIANTS_LABEL = {
  DETAILED: 'detailed',
  SIMPLE: 'simple',
};

const DISPLAY_VARIANTS = {
  [DISPLAY_VARIANTS_LABEL.DETAILED]: <ToggleDetailedIcon />,
  [DISPLAY_VARIANTS_LABEL.SIMPLE]: <TogglePlainIcon />,
};

export const ProductsList = React.memo(({ report_id }) => {
  const [display, setDisplay] = useState(DISPLAY_VARIANTS_LABEL.DETAILED);

  const location = useLocation();
  const reportFinished = useSelector(isReportFinished);

  const { getQueryParam, setQueryParam } = useUrlState();

  const page_size = Number(getQueryParam('page_size')) || 10;
  const page_num = Number(getQueryParam('page_num')) || 1;
  const choosenFilterPreset = getQueryParam('filter_preset') || FILTER_PRESET_LABELS.CREATED_DESC;

  const { data, isLoading, refetch } = useGetProductsQuery(
    {
      report_id: report_id,
      page_size,
      page_num,
      sort_by: FILTER_PRESETS[choosenFilterPreset].sort.field,
      sort_order: FILTER_PRESETS[choosenFilterPreset].sort.order,
    },
    {
      pollingInterval: reportFinished ? 0 : 15000,
      skip: !report_id,
    }
  );

  useEffect(() => {
    if (reportFinished && data?.data) {
      refetch();
    }
  }, [reportFinished]);

  const goToPage = useCallback(
    (page) => {
      setQueryParam('page_num', page);
    },
    [setQueryParam]
  );

  const handleLimitChange = useCallback(
    (value) => {
      setQueryParam('page_size', value);
      setQueryParam('page_num', 1);
    },
    [setQueryParam]
  );

  useScrollRestoration([location?.search], 'pageStart');

  const handleChangeDisplay = (_, value) => {
    if (value !== null) {
      setDisplay(value);
    }
  };

  return (
    <div className="tw-flex tw-flex-col tw-gap-5 tw-w-full" id="pageStart">
      <div className="tw-flex tw-flex-row tw-items-center tw-justify-between tw-w-full projectTablet:tw-flex-wrap-reverse projectTablet:tw-gap-y-5">
        <div className="tw-flex tw-flex-row tw-items-center tw-gap-[25px] projectTablet:tw-w-full projectTablet:tw-justify-between projectMobile:tw-flex-col projectMobile:tw-items-start projectMobile:tw-gap-4">
          <ProductFilter />
          <ToggleButtonGroup
            className="tw-bg-[#E6E6E6] tw-h-[46px] tw-rounded-lg"
            exclusive
            value={display}
            onChange={handleChangeDisplay}
          >
            {Object.entries(DISPLAY_VARIANTS).map(([variant, icon]) => (
              <ToggleButton
                disableRipple
                value={variant}
                aria-label={variant}
                key={variant}
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: '#FFFFFF',
                  },
                }}
              >
                {icon}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </div>
        <ScreenshotsMaker />
      </div>
      <div className="tw-flex tw-flex-col tw-h-full tw-w-full tw-gap-8 tw-justify-between">
        {display === DISPLAY_VARIANTS_LABEL.DETAILED && (
          <>
            <div className="tw-flex tw-flex-col tw-w-full tw-gap-4">
              {isLoading ? (
                <>
                  <ProductSkeleton />
                  <ProductSkeleton />
                  <ProductSkeleton />
                </>
              ) : (
                data?.data?.map((item, index) => {
                  return (
                    <Product
                      product={item}
                      key={item?.id}
                      productIndex={index}
                      orderNumber={calculateProductOrderNumber(index, page_num, page_size)}
                    />
                  );
                })
              )}
            </div>
          </>
        )}
        {display === DISPLAY_VARIANTS_LABEL.SIMPLE && <ProductsTable rows={data?.data} />}
        <div className="tw-w-full tw-mt-4">
          <Pagination
            page={page_num}
            count={data?.count}
            rowsPerPage={page_size}
            onPageChange={goToPage}
            onLimitChange={handleLimitChange}
          />
        </div>
      </div>
    </div>
  );
});
